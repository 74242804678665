table { width : 100%; }
td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
}

#overlay{
    /*　画面全体を覆う設定　*/
      position:fixed;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-color:rgba(0,0,0,0.5);
    
      /*　画面の中央に要素を表示させる設定　*/
      display: flex;
      align-items: center;
      justify-content: center;
    
}

.modal-dialog-fluid {
    max-width: inherit;
    width: 98%;
    margin-left: 15px;
  }